.videoCard {
  width: 270px;
  margin-bottom: 40px;
}
.videoCard__thumbnail {
  height: 140px;
  width: 250px;
}
.videoCard__info {
  display: grid;
  grid-template-columns: 3fr 1fr;
  margin-top: 10px;
  padding-right: 30px;
}

.video__text {
  margin-left: 15px;
}

.video__text > h4 {
  font-size: 14px;
  margin-bottom: 5px;
}
.video__text > p {
  font-size: 14px;
  color: gray;
}

.videoCard__info__Watch__Later__btn {
  color: lightblue;
  margin-left: 50%;
  border: none;
}
.videoCard__info__Watch__Later__btn:active {
  color: black;
}
.WatchLater__red {
  color: red;
}
.videoCard__info__Watch__Later__div {
  background-color: transparent !important;
}
