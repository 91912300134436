.sidebar {
  flex: 0.2;
}
.sidebarRow {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px 20px;
  text-decoration: none !important;
}
.sidebarRow__icon {
  color: #606060;
  font-size: large !important;
}
.sidebarRow__title {
  flex: 1;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 500;
}
.sidebarRow:hover {
  background-color: lightgray;
  cursor: pointer;
}
.sidebarRow:hover > .sidebarRow__icon {
  color: red;
}
.sidebarRow__h2 {
  text-decoration: none !important;
  color: #606060 !important;
}
