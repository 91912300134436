.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: white;
  padding: 20px;
}
/*  object-fit: contain used with img to make them fit  */
.header__logo {
  height: 25px;
  object-fit: contain;
  margin-left: 20px;
}
/* .header__topLeft {
} */
.header__topCenter {
  display: flex;
  align-items: center;
  width: 40%;
  border: 1px solid black;
}
.header__topCenter > input {
  flex: 1;
  border: none;
  padding-left: 2%;
}

.header__inputButton {
  width: 50px;
  min-height: 100% !important;
  background-color: #fafafa;
  border-left: 1px solid lightgray;
  color: gray;
}
.header__icon {
  margin-right: 7px;
}
.header__topRight {
  display: flex;
  align-items: center;
}
Link {
  text-decoration-style: none !important;
  color: gray !important;
}
