.recommendeVideos {
  background-color: #f9f9f9;
  padding: 40px 20px;
  padding-bottom: 0;
  flex: 0.8;
}

.recommendeVideos > h2 {
  margin-left: 5px;
  margin-bottom: 20px;
}
.recommendeVideos__videos {
  display: flex;
  flex-wrap: wrap;
}
