@import "~bootstrap/dist/css/bootstrap.css";
* {
  margin: 0;
  padding: 0;
}
.App {
  text-align: center;
}

.App__grid {
  display: flex;
}
